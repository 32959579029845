<template>
  <v-row>
    <v-col class="d-flex justify-center">
      <v-card width="500" height="300">
        <v-row no-gutters style="height:300px" align="center">
          <v-col v-if="invoice.debit===0">
            <p class="text-h2 text-center">Dank u wel</p>
            <p class="text-h4 text-center">Uw factuur is betaald</p>
          </v-col>
          <v-col v-else>
            <p class="text-h2 text-center">Helaas</p>
            <p class="text-h4 text-center">De betaling is niet gelukt</p>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { config } from "../config/config"
export default {
  name: "redirect",
  data() {
    return {
      invoice: {},
      invoiceStatus: ""
    }
  },
  methods: {
    async fetchInvoice(_id) {
      let data = JSON.stringify({ _id: _id })
      let res = await fetch(`${config.payments.host}/fetchInvoice/${data}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      let response = await res.json()
      this.invoice = response[0]
    }
  },
  mounted() {
    this.fetchInvoice(this.$route.query.id)
  }
}
</script>
<style scoped>
</style>